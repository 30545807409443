:root {
  --background-color: #f7f7f7;
  --dark-color: #212121;
  --blue-color: #429df0;
  --second-blue-color: #4c8dca;
  --blue-text-color: #5886b0;
  --dark-gray-color: #5e6064;
  --gray-color: #b2abab;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p,
li {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
  font-family: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
  overflow-x: hidden;

  overflow-y: scroll;
  scrollbar-width: thin;

  scrollbar-gutter: stable;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
